import React from 'react'
import linkdine  from '../../assets/img/linkdine.svg'
import linkdinehover from '../../assets/img/linkdine-hover.svg'

import facebook from '../../assets/img/facebook.svg'
import facebookhover from '../../assets/img/facebook-hover.svg'

import instagram from '../../assets/img/instagram.svg'
import instagramhover from '../../assets/img/instagram-hover.svg'

import youtube from '../../assets/img/youtube.svg'
import youtubehover from '../../assets/img/youtube-hover.svg'

import twitter from '../../assets/img/twitter.svg'
import twitterhover from '../../assets/img/twitter-hover.svg'

import dribble from '../../assets/img/dribble.svg'
import dribblehover from '../../assets/img/dribble-hover.svg'

// import footerlogo from '../../assets/img/footer-logo.png'


const footer = () => {
  return (
    <div className="ftrDetails clearfix">
    <div className="container">
        <div className="ftrInfo d-flex justify-content-between align-items-center">
               {/* company logo */}
            <div className="copyTxt">
                <a href="https://www.zignuts.com/" target="_blank" rel="noreferrer"> </a>

                <a href="https://www.zignuts.com/?utm_source=Google-MyCIDR.net&utm_medium=Footer-Logo&utm_campaign=MyCIDR.net" target="_blank" rel="noreferrer">
                    <p className="d-lg-inline-block d-block me-lg-2 me-0">Proudly made by</p>
                     <img src='https://global-uploads.webflow.com/62861fb0faf45bf95f09d5c2/63b53e41c6c0f9769753d26f_Zignuts%20Original%20Logo%20(Light-White)-svg.svg'  max-width="115px" height="35px" alt="logo" className="d-inline-block" /> 
                </a>
            </div>

           {/* Social Media  */}
            <div className="ftrSocial">
                <span>Social Media</span>
                <ul className="list-unstyled m-0">
                    <li>
                        <a href="https://www.linkedin.com/company/zignuts-technolab/" rel="author noreferrer" target='_blank' >
                            <img src={linkdine} alt="linkdine" className="onhover" />
                            <img src={linkdinehover} alt="linkdine" className="offhover" />
                        </a>
                    </li>
                    <li>
                        <a href="https://www.facebook.com/ZignutsTechnolab/" rel="author noreferrer" target="_blank">
                            <img src={facebook} alt="facebook" className="onhover" />
                            <img src={facebookhover} alt="facebook" className="offhover" />
                        </a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/zignutstechnolab/" rel="author noreferrer" target="_blank">
                            <img src={instagram} alt="instagram" className="onhover" />
                            <img src={instagramhover} alt="instagram" className="offhover" />
                        </a>
                    </li>
                    <li>
                        <a href="https://www.youtube.com/channel/UCo7jfXlmkOtjhzhIHnw8rdw/featured" rel="author noreferrer" target="_blank">
                            <img src={youtube} alt="youtube" className="onhover" />
                            <img src={youtubehover} alt="youtube" className="offhover" />
                        </a>
                    </li>
                    <li>
                        <a href="https://twitter.com/ZignutsTechno" rel="author noreferrer"   target="_blank">
                            <img src={twitter} alt="twitter" className="onhover" />
                            <img src={twitterhover} alt="twitter" className="offhover" />
                        </a>
                    </li>
                    <li>
                        <a href="https://dribbble.com/ZignutsTechnolab" rel="author noreferrer"  target="_blank">
                            <img src={dribble} alt="dribbble" className="onhover" />
                            <img src={dribblehover} alt="dribbble" className="offhover" />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
  )
}

export default footer
