import './assets/styles/style.css'
import './assets/styles/media.css'
import ValueBox from './components/ValueBox';
import About from './view/pages/About';
import Footer from './view/pages/Footer'
import ThemeChange from './view/pages/ThemeChange';

function App() {

  return (
    <div className="App">
        <ThemeChange />
        <About />
        <ValueBox />
        <Footer />
    </div>
  );
}


export default App;
