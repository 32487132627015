import React from "react";

const ThemeChange = () => {

  // Change Theme Based on Click button
  const handleChange = (e) => {
    if (e.target.checked === true) {
      const checkbox = document.getElementById("checkbox");
      console.log('checkbox: ', checkbox);
      checkbox.addEventListener("change", () => {
        document.body.classList.toggle("dark");
      });
    } else {
      const checkbox = document.getElementById("checkbox");
      console.log('checkbox: ', checkbox);
      checkbox.addEventListener("change", () => {
        document.body.classList.remove("dark");
      });
    }
  };

  return (
    <div>
      <div>
        {/* Change Theme */}
        <div className="hdrtoogle">
          <div className="container">
            <div className="position-relative">
              <div className="togleInfo position-absolute">
                <input
                  type="checkbox"
                  className="checkbox"
                  id="checkbox"
                  onChange={(e) => handleChange(e)}
                />
                <label for="checkbox" className="label">
                  <div className="ball"></div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThemeChange;
