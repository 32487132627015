import React, { useState } from "react";
import tblcopy from "../assets/img/tblcopy.png";
import tblcopddark from "../assets/img/tblcopd-dark.png";
import { CopyToClipboard } from "react-copy-to-clipboard";

const DetailTable = (props) => {
  const tableDatas = props.tableData;
  const ipAddressAnd = props.ipAddress;

  // For Copy Button
  const [value, setValue] = useState(ipAddressAnd);
  const [copied, setCopied] = useState(false);
  const otherChange = (e) => {
    setValue(e.target.value, { copied: copied });
  };

  const otherCopy = () => {
    setValue(ipAddressAnd);
    setCopied(true);
  };

  return (
    // Subnet Added Table
    <div>
      <div className="subDetail clearfix">
        <div className="container">
          <div className="tbldetails">
            <div className="tblTitle d-flex justify-content-between align-items-center">
              <h2>Subnet Address Details</h2>
              <button onClick={() => props.clearData()}>Clear all</button>
            </div>
            <div class="tblnfo">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Sr No.</th>
                    <th scope="col">Subnet Address</th>
                    <th scope="col">Host Address Range</th>
                    <th scope="col">Count</th>
                  </tr>
                </thead>

                {tableDatas.length !== 0 ? (
                  tableDatas.map((result, index) => {
                    return (
                      <tbody>
                        <tr>
                          <td>{index + 1}</td>
                          <td className="table-data">
                            {result.ipAddress}
                            <CopyToClipboard onCopy={otherCopy} text={value}>
                              <img
                                src={tblcopy}
                                alt="copy"
                                title="Copy"
                                className="lightcopy"
                                value={value}
                                onChange={otherChange}
                                onClick={() =>
                                  navigator.clipboard.writeText(ipAddressAnd)
                                }
                              />
                            </CopyToClipboard>

                            <CopyToClipboard onCopy={otherCopy} text={value}>
                              <img
                                src={tblcopddark}
                                alt="copy"
                                title="Copy"
                                className="darkcopy"
                                onChange={otherChange}
                                onClick={() =>
                                  navigator.clipboard.writeText(ipAddressAnd)
                                }
                              />
                            </CopyToClipboard>
                          </td>
                          <td>
                            {result.firstUsableIPValue} -{" "}
                            {result.lastUsableIPValue}
                          </td>
                          <td>{result.countValue}</td>
                        </tr>
                      </tbody>
                    );
                  })
                ) : (
                  <tbody>
                    <tr colSpan="4" className="nodata">
                      <td
                        colSpan="4"
                        className="justify-content-center align-items-center"
                      >
                        Data Not Found
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailTable;
