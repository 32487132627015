import React, { useEffect, useState } from "react";
import copy from "../assets/img/copy.png";
import copymobile from "../assets/img/copy-mobile.png";
import DetailTable from "./DetailTable";
import { CopyToClipboard } from "react-copy-to-clipboard";
const Netmask = require("netmask").Netmask;

const ValueBox = () => {
  const [ipAddress, setIpAddress] = useState();

  const [ipv4, setIpv4] = useState({
    netmaskValue: "255.255.255.0",
    firstUsableIPValue: "256",
    lastUsableIPValue: "10.0.1.1",
    countValue: "256",
  });

  const [tableData, setTableData] = useState([]);

  // For Copy Button
  const [value, setValue] = useState(ipAddress);
  const [copied, setCopied] = useState(false);

  const otherChange = (e) => {
    setValue(e.target.value, { copied: copied });
  };

  const otherCopy = () => {
    setValue(ipAddress);
    setCopied(true);
  };

  // Functionality of Change Input box(Auto Focus)
  const tabChange = function (value) {
    const val = parseInt(value);

    let ele = document.querySelectorAll("input");

    if (ele[val].value !== "") {
      ele[val + 1].focus();
    } else if (ele[val].value === "") {
      ele[val - 1].focus();
    }
  };

  // Set Default Input
  const [ipInput, setIpInput] = useState({
    decimalNumber1: "10",
    decimalNumber2: "0",
    decimalNumber3: "1",
    decimalNumber4: "0",
  });

  // Set Default Subnet
  const [subnet, setSubnet] = useState({
    subnetMask: "24",
  });

  useEffect(() => {
    // Check If Input is Null - if null put 0 at that Input Box
    /*eslint-disable */
    ipInput.decimalNumber1 === ""
      ? (ipInput.decimalNumber1 = 0)
      : (ipInput.decimalNumber1 = ipInput.decimalNumber1);
    ipInput.decimalNumber2 === ""
      ? (ipInput.decimalNumber2 = 0)
      : (ipInput.decimalNumber2 = ipInput.decimalNumber2);
    ipInput.decimalNumber3 === ""
      ? (ipInput.decimalNumber3 = 0)
      : (ipInput.decimalNumber3 = ipInput.decimalNumber3);
    ipInput.decimalNumber4 === ""
      ? (ipInput.decimalNumber4 = 0)
      : (ipInput.decimalNumber4 = ipInput.decimalNumber4);
    subnet.subnetMask === ""
      ? (subnet.subnetMask = 0)
      : (subnet.subnetMask = subnet.subnetMask);

    generateIp4();
  }, [ipInput, subnet]);

  // Validation (Only Number allowed && Special Symbol is Not Allowed)
  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-", "."].includes(e?.key) && e?.preventDefault();

  // Validation For Numbers in Ip num < 257 && in Subnet < 33 is Allowed
  const handleSubnet = (e) => {
    if (
      e.target.value !== "" &&
      e.target.value.length < 3 &&
      e.target.value >= 0 &&
      e.target.value < 33
    ) {
      setSubnet({ ...subnet, [e.target.name]: e.target.value });
    } else if (e.target.value === "") {
      tabChange(e.target.id);
      setSubnet({ ...subnet, [e.target.name]: e.target.value });
    } else {
      tabChange(e.target.id);
      e.preventDefault();
    }
  };

  const handleChange = (e) => {
    if (
      e.target.value !== "" &&
      e.target.value.length <= 3 &&
      e.target.value >= 0 &&
      e.target.value < 257
    ) {
      setIpInput({ ...ipInput, [e.target.name]: e.target.value });
    } else if (e.target.value === "") {
      tabChange(e.target.id);
      setIpInput({ ...ipInput, [e.target.name]: e.target.value });
    } else {
      tabChange(e.target.id);
      e.preventDefault();
    }
  };

  const handleTableValue = (e) => {
    e.preventDefault();
    const newData = {
      netmaskValue: ipv4.netmaskValue,
      firstUsableIPValue: ipv4.firstUsableIPValue,
      lastUsableIPValue: ipv4.lastUsableIPValue,
      countValue: ipv4.countValue,
      ipAddress: ipAddress,
    };
    const temp = tableData.filter((e) => e.ipAddress === newData.ipAddress);

    if (temp.length === 0) {
      setTableData([...tableData, newData]);
    }
  };

  // Clear The Table Data
  const clearData = () => {
    setTableData([]);
  };

  // Logic Of Subnet Range Generator
  const generateIp4 = () => {
    const ip4 =
      ipInput.decimalNumber1 +
      "." +
      ipInput.decimalNumber2 +
      "." +
      ipInput.decimalNumber3 +
      "." +
      ipInput.decimalNumber4 +
      "/" +
      subnet.subnetMask;

    setIpAddress(ip4);
    const block = new Netmask(ip4); // Used Netmask npm package for Range Generator
    setIpv4({
      netmaskValue: block.mask,
      firstUsableIPValue: block.first,
      lastUsableIPValue: block.last,
      countValue: block.size,
    });
  };

  return (
    <>
      <div>
        <div>
          <div className="abtIp text-center">
            <div className="container">
              <div className="idInfo">
                <form>
                  <div className="idspc">
                    <p className="mb-4">
                      {" "}
                      Enter the IP address with each bit or decimal in its
                      separate box with values between 0 to 255. <br /> Enter
                      the subnet mask between 0 to 32.{" "}
                    </p>
                    <div>
                      <div className="idDetails d-sm-flex d-block justify-content-center align-items-center">
                        <ul className="list-unstyled m-0">
                          <li>
                            <input
                              id="1"
                              type="number"
                              name="decimalNumber1"
                              value={ipInput.decimalNumber1}
                              minLength={3}
                              min="0"
                              max="255"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onKeyDown={blockInvalidChar}
                              className="color-1"
                            />
                            <span>.</span>
                          </li>
                          <li>
                            <input
                              id="2"
                              type="number"
                              name="decimalNumber2"
                              value={ipInput.decimalNumber2}
                              min="0"
                              max="255"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onKeyDown={blockInvalidChar}
                              className="color-2"
                            />
                            <span>.</span>
                          </li>
                          <li>
                            <input
                              id="3"
                              type="number"
                              name="decimalNumber3"
                              value={ipInput.decimalNumber3}
                              min="0"
                              max="255"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onKeyDown={blockInvalidChar}
                              className="color-3"
                            />
                            <span>.</span>
                          </li>
                          <li>
                            <input
                              id="4"
                              type="number"
                              name="decimalNumber4"
                              value={ipInput.decimalNumber4}
                              min="0"
                              max="255"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onKeyDown={blockInvalidChar}
                              className="color-4"
                            />
                            <span>/</span>
                          </li>
                          <li>
                            <input
                              id="5"
                              type="number"
                              name="subnetMask"
                              value={subnet.subnetMask}
                              min="0"
                              max="32"
                              maxLength={2}
                              onChange={(e) => {
                                handleSubnet(e);
                              }}
                              onKeyDown={blockInvalidChar}
                              className="color-5"
                            />
                          </li>
                        </ul>

                        <div className="copyIcon">
                          <span className="d-sm-none d-inline-block">copy</span>
                          <div className="copyimg">
                            <CopyToClipboard onCopy={otherCopy} text={value}>
                              <img
                                src={copy}
                                alt="copy"
                                className="d-sm-inline-block d-none copyBtnhvr"
                                title="Copy"
                                onChange={otherChange}
                                onClick={() =>
                                  navigator.clipboard.writeText(ipAddress)
                                }
                              />
                            </CopyToClipboard>

                            <CopyToClipboard onCopy={otherCopy} text={value}>
                              <img
                                src={copymobile}
                                className="d-sm-none d-inline-block copybtndec"
                                alt="copy"
                                title="Copy"
                                onChange={otherChange}
                                onClick={() =>
                                  navigator.clipboard.writeText(ipAddress)
                                }
                              />
                            </CopyToClipboard>
                          </div>
                        </div>
                        <input
                          type="text"
                          id="myInput"
                          style={{ display: "none" }}
                        />
                      </div>
                    </div>

                    <div className="listidInfo">
                      <div className="idList">
                        <span>Netmask</span>
                        <p>{ipv4.netmaskValue}</p>
                      </div>
                      <div className="idList">
                        <span>Total Count</span>
                        <p>{ipv4.countValue}</p>
                      </div>
                      <div className="idList">
                        <span>From</span>
                        <p>{ipv4.firstUsableIPValue}</p>
                      </div>
                      <div className="idList">
                        <span>To</span>
                        <p>{ipv4.lastUsableIPValue}</p>
                      </div>
                    </div>
                  </div>
                  <div className="addTablebtn">
                    <button type="submit" onClick={(e) => handleTableValue(e)}>
                      Add to table
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DetailTable
        tableData={tableData}
        ipInput={ipInput}
        ipAddress={ipAddress}
        clearData={() => clearData()}
      />
    </>
  );
};

export default ValueBox;
